@import url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap);
/**
 * @TODO Determine what belongs in MUI theme and what should remain here
 * @ticket https://gohypergiant.atlassian.net/browse/JUPTR-1412
 */

:root {
  --PRIMARY_MAIN: #232f78;
  --PRIMARY_LIGHT: #646bf6;
  --PRIMARY_ALT: #3182ce;
  --TEXT_PRIMARY: #2d3748;

  --colorGreyLightest: #f2f2f2;
  --colorGreyLighter: hsl(235, 21%, 90%);

  --colorBrandBlueLight: hsl(219, 77%, 95%);

  --colorDanger: hsl(1, 77.2%, 55.3%);
  --colorWarning: hsl(40, 100%, 50%);
  --colorSuccess: #1bc172;

  --maxWidth: 1440px;
}

body {
  font-family: 'Mulish', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #646bf6;
  color: var(--PRIMARY_LIGHT);
  font-weight: 600;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

fieldset {
  border: 0 none;
  box-sizing: border-box;
  display: block;
  margin: 0;
  padding: 0;
  width: 100% !important;
}

legend {
  color: #2d3748;
  color: var(--TEXT_PRIMARY);
  font-size: 1.25rem;
  font-weight: 600;
}

hr {
  border-bottom: 1px solid #dfe0eb;
  border-top: none;
  display: block;
  margin: 2rem 0;
  width: 100% !important;
}

h2 {
  margin-top: 0;
  margin-bottom: 0.75rem;
}

/* Absolutely-position helper text as to not impact page layout */
.MuiFormHelperText-root {
  bottom: 0;
  left: -1rem;
  position: absolute;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  width: 100%;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiFilledInput-root {
  background-color: transparent !important;
}

/* TODO: Remove once tabs are normalized */
.RaTabbedForm-content-58 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.searchField *::after,
.searchField *::before {
  border: 0 none !important;
}

.searchField > div,
.searchField > div:hover {
  transition: none;
  background-color: #f7f8fc !important;
  border-radius: 8px;
}

.searchField {
  width: 25rem;
}
.MuiInputBase-inputHiddenLabel + fieldset legend {
  display: none;
}

[class*='RaAutocompleteArrayInput-chipContainerFilled'] {
  margin: -5px 0px 0px 0px !important;
}

[class*='RaEdit-root'] {
  background-color: white;
  border-radius: 8px;
}

[role='tabpanel'] {
  background-color: white;
}

#main-content {
  padding: 1.5rem;
}

/* TODO: See if we have a loader and path for static assets... */
.Device {
  --assetWidth: 859;
  --assetHeight: 1724;

  aspect-ratio: 859 / 1724;
  box-sizing: border-box;
  display: flex;
  position: relative;
  width: 100%;
}

.Device::after {
  background-image: url('https://res.cloudinary.com/hypergiant/image/upload/v1653657356/assets/device-frame_ifuopy.png');
  background-size: cover;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
  pointer-events: none;
}

.Device > * {
  box-sizing: border-box;
  height: calc(1625 / var(--assetHeight) * 100%);
  left: calc(54 / var(--assetWidth) * 100% - 1px);
  overflow: scroll;
  position: absolute;
  top: calc(50 / var(--assetHeight) * 100%);
  width: calc(750 / var(--assetWidth) * 100% + 2px);
}

.Device-overlay {
  align-items: center;
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  background-color: hsla(0, 0%, 100%, 0.8);
  border-radius: 1.5em;
  display: flex;
  font-size: 1.75em;
  font-weight: 600;
  justify-content: center;
  text-shadow: 0 0 0.5em white;
  text-transform: uppercase;
  transition: -webkit-backdrop-filter 200ms ease;
  transition: backdrop-filter 200ms ease;
  transition: backdrop-filter 200ms ease, -webkit-backdrop-filter 200ms ease;
  z-index: 3;
}

.Device-overlay:hover {
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
}

.SubNav {
  background-color: hsla(232, 52%, 20%, 1);
  box-sizing: border-box;
  height: 4rem;
  margin: 0;
  margin-bottom: 1.5rem;
  color: hsla(0, 0%, 100%, 1);
  position: relative;
  margin-top: calc(-2.25rem - 1px);
  padding: 0;
  left: -1.5rem;
  right: -1.5rem;
  width: calc(100% + 3rem);
}

.SubNav-content {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
}

.Table {
  border: 1px solid hsl(235, 21%, 90%);
  border: 1px solid var(--colorGreyLighter);
}

.Table-row {
  display: flex;
  justify-content: space-between;
}

.Table-row:not(:last-of-type) {
  border-bottom: 1px solid hsl(235, 21%, 90%);
  border-bottom: 1px solid var(--colorGreyLighter);
}

.Table-cell {
  width: 100%;
  padding: 0.25rem 0.5rem;
  max-width: 100%;
  /* font-size: 0.875rem; */
}

.Table-cell:not(:last-of-type) {
  border-right: 1px solid hsl(235, 21%, 90%);
  border-right: 1px solid var(--colorGreyLighter);
}

a:hover {
  text-decoration: underline;
}

.u-centerFlexBetween {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.Card {
  padding: 0.75rem 1rem;
  background-color: white;
  border: 1px solid hsl(235, 21%, 90%);
  border: 1px solid var(--colorGreyLighter);
  border-radius: 1rem;
}

.Container {
  background-color: none;
  border: none;
  box-shadow: none;
  margin: 0 auto;
  max-width: 1440px;
  max-width: var(--maxWidth);
  padding: 0;
  width: 100%;
}

.Profile {
  box-sizing: border-box;
  color: #232f78;
  color: var(--PRIMARY_MAIN);
  cursor: pointer;
  font-size: 16px;
  height: 100%;
  padding-top: 24%;
  position: relative;
}

.Profile-background {
  aspect-ratio: 390 / 200;
  background-image: url('https://res.cloudinary.com/hypergiant/image/upload/v1642046787/profile-backgrounds/profile_background_5_hows8m.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-left-radius: 1.25em;
  border-bottom-right-radius: 1.25em;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.Profile-card {
  background-color: white;
  border-radius: 0.5em;
  box-shadow: 0em 0.25em 0.625em rgba(9, 37, 81, 0.15);
  box-sizing: border-box;
  display: flex;
  margin: 1.25em;
  padding: 1em;
  transition-duration: 300ms;
  transition-property: opacity;
  transition-timing-function: ease-out;
}

.Profile--user .Profile-card {
  align-items: center;
  flex-direction: column;
  padding-bottom: 2em;
}

.Profile--user .Profile-avatar {
  margin-top: calc(-3.125em - 1em);
  margin-bottom: 0.75em;
}

.Profile--business .Profile-card {
  height: 5em;
  margin-top: 3em;
  padding: 0.5em;
}

.Post {
  --padding: 1em;
  font-size: 16px;

  padding: var(--padding);
  width: 100%;
  box-sizing: border-box;
}

.Post-author {
  align-items: center;
  display: flex;
  margin-bottom: 1em;
}

.Post-media {
  margin-bottom: 1em;
  position: relative;
}

.Post-media--container {
  aspect-ratio: 390/288;
  margin-left: calc(-1 * var(--padding));
  margin-right: calc(-1 * var(--padding));
  width: calc(100% + 2 * var(--padding));
  position: relative;
}

.Post-media-control {
  --size: 2em;

  background-color: white;
  border-radius: 50%;
  border: none;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  font-size: 1em;
  height: var(--size);
  left: 0;
  margin-top: calc(var(--size) / -2);
  outline: none;
  position: absolute;
  top: 50%;
  width: var(--size);
  z-index: 2;
}

.Post-media-control:last-of-type {
  left: auto;
  right: 0;
}

.Post-media-control:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.Post-media-control svg {
  display: block;
  height: 0.5em;
  margin: auto;
  width: 0.5em;
  fill: #232f78;
  fill: var(--PRIMARY_MAIN);
}

.Post-media img,
.Post-media video {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
  position: absolute;
}

.Post-media-pagination {
  background-color: #232f78;
  background-color: var(--PRIMARY_MAIN);
  border-radius: 1em;
  bottom: 1em;
  color: white;
  font-size: 0.625em;
  font-weight: 600;
  line-height: 1;
  padding: 0.25em 0.5em;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 2;
}

.Comment {
  display: flex;
}

.Comment-body {
  background-color: #f2f2f2;
  background-color: var(--colorGreyLightest);
  border-radius: 0.375em;
  box-sizing: border-box;
  padding: 0.5em;
  margin-bottom: 1em;
}

.Comment-avatar {
  margin-right: 0.5em;
}

/* Turtles all the way down */
.Comment .Comment .Comment-avatar {
  font-size: 1em;
  height: 1.875em;
  width: 1.875em;
}

.Comment .Comment .Comment .Comment-avatar {
  font-size: 1em;
  height: 1.25em;
  width: 1.25em;
}

